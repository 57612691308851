import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CarouselComponentDt from '../data/CarouselComponentDt';

const Astrodetails = () => {

  // image change onclick start//
  const [bigImage, setBigImage] = useState("../shopimg/st01.jpg");
  
  const thumbnailimg = [
    "../shopimg/st01.jpg",
    "../shopimg/st02.jpg",
    "../shopimg/st03.jpg",
    "../shopimg/st04.jpg",
    "../shopimg/st05.jpg"
  ];

  const handleThumbnailClick = (image) =>{
    setBigImage(image);
  }

  // image change onclick end//

  // start reviwe Write
  const [ratingreview, setRatingreview] = useState(0);

  const handleClickrating = (index) => {
    setRatingreview(index + 1);
  };

  // start reviwe Write end

  
  // start reviwe Write
  const [writeRating, setWriterating] = useState(0);

  const handleClickwrite = (index) => {
    setWriterating(index + 1);
  };

  // start reviwe Write end
  

  // quantit function
function QuantityInput() {
  const [quantity, setQuantity] = useState(1);
  const handleIncrease = () => {
    if (quantity <= 100) {
      setQuantity(quantity + 1);
    }
  };
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
<>
<div className="main_div_flx_add">
  <h4>Quantity</h4>
<div className="quantity products_qty_input">
      <button className="minus" aria-label="Decrease" onClick={handleDecrease}>-</button> 
      <input type="number" className="input-box" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}  min={1}  max={10} />
      <button className="plus" aria-label="Increase" onClick={handleIncrease}>+</button>
           
    </div>
</div>
</>
  );
}


  return (
    <>
    <section className="astromall_dt_section">
      <div className="container">
      
        <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
            <div className="left_title_div">
                <h1>Yellow sapphire (pukhraj) 5.25</h1>
            </div>
                {/* nested row start */}
                 <div className="row justify-content-center">
                     {/*nested col start */}
          <div className="col-lg-2 col-md-2 col-sm-12 col-12">
          <div className="astromall_thumbnail_slider_main">
          <div className="thumbnail_img_box">
  { thumbnailimg.map((thumbnailimg, index) => (
    <img src={thumbnailimg} key={index} alt="" onClick={() => handleThumbnailClick(thumbnailimg)} className='img-fluid' />
  )) }  
 </div>
 </div>
          </div>
           {/* nested col end */}

            {/*nested col start */}
          <div className="col-lg-10 col-md-10 col-sm-12 col-12">
          <div className="astromall_big_img_box">
          <img src={bigImage} alt="" className="img-fluid desktop_astro_img" />
          <CarouselComponentDt/>
          </div>
          {/* product category div start */}
          <div className="product_cate_dt_div_page">
            <h3>products category</h3>

            <div className="pro_category_img_box">
              {/*  */}
   <div className="pro_cate_img_box_box">
   <img src="../shopimg/st01.jpg" alt="" className='img-fluid' />
   <p><b>5.25 ratti</b></p>
   </div>
    {/*  */}
   {/*  */}
   <div className="pro_cate_img_box_box">
   <img src="../shopimg/st02.jpg" alt="" className='img-fluid' />
   <p><b>5.25 ratti</b></p>
   </div>
    {/*  */}

    {/*  */}
   <div className="pro_cate_img_box_box">
   <img src="../shopimg/st03.jpg" alt="" className='img-fluid' />
   <p><b>5.25 ratti</b></p>
   </div>
    {/*  */}

            </div>

          </div>
          {/* product category div end */}
           </div>
            {/*nested col end */}
                 </div>
                {/* nested row end */}
            </div>
            {/* col end */}

               {/* col start */}
               <div className="col-lg-5 col-md-7 col-sm-12 col-12">
                 {/*nested col start */}
           
           <div className="col-lg-11 col-md-12 col-sm-12 col-12">
            {/*  */}
           <div className="right_side_serach_div">
                 <form action="">
                 <div class="input-group">
                 <button className="btn" type="submit"><i class='bx bx-search-alt'></i></button>
                   <input type="text" placeholder="Find Astrologer..." className='form-control'/>
                 </div>
                 </form>
              </div>
              {/*  */}

              {/* main div right add md */}
             <div className="rightside_dt_txt_main_div">
              {/* md object  */}
              <div className="media_object_main_div">
             <div className="d-flex">
  <div className="flex-shrink-0">
  <i class='bx bx-map'></i>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="media_object_txt_div">
      <b>Delivery Address</b>
      <div className="md_flx">
      <p>Block-d, pioneer urban square, sector 62, ghata, gurugram, haryana-122102</p>
      <Link to=""><i class='bx bxs-edit'></i></Link>
      </div>
    </div>
  </div>
</div>
</div>
 {/* md object end */}

  {/* md object  */}
  <div className="media_object_main_div">
             <div className="d-flex">
  <div className="flex-shrink-0">
  <i class='bx bx-map'></i>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="media_object_txt_div">
      <b>Delivery Date</b>
      <p className='free_delivery'>Free Delivery <strong>13 july.</strong></p>
{/* radio button */}
<div className="radio_btn_div">
  <form action>
    <div className="form-check">
      <label className="form-check-label" htmlFor="rd1">Add fast delivery option. </label>
      <input className="form-check-input" type="radio" name="rd" id="rd1" defaultChecked/>
    </div>

    <div className="form-check">
      <label className="form-check-label" htmlFor="rd2">Add delivery protection. </label>
      <input className="form-check-input" type="radio" name="rd" id="rd2" />
    </div>

    <div className="form-check">
      <label className="form-check-label" htmlFor="rd3">One day delivery option. </label>
      <input className="form-check-input" type="radio" name="rd" id="rd3" />
    </div>
  </form>
</div>
{/* radio button end*/}

{/* quatity div */}
{/*  */}
<QuantityInput />
{/*  */}
{/* end */}

{/* price div and button start */}
<div className="price_and_bttuon_div">
  <p>
    <b className='price_txt'>₹1,461 <small>.00</small> </b>
    <span className='price_discount'>₹ 1,461 <small>.00</small> </span>
    <span className='price_off'>10% OFF</span>
    </p>

    <div className="buy_button_div">
      <span className='fa fa-shopping-cart'></span>
      <p><a href=""> buy now</a></p>
    </div>
    <p className='secure_transi'> Secured transaction</p>
</div>
{/* price div and button end */}

    </div>
  </div>
</div>
</div>
 {/* md object end */}
 </div>
    {/* main div right add md end */}
</div>
       {/*nested col end */}
   </div>
      {/* col end */}

        </div>
      </div>
    </section>


{/*  */}
<section className="pro_description_section">
  <div className="container">
    <div className="row justify-content-center">
      {/* col start */}
      <div className="col-lg-10 col-md-12 col-sm-12 col-12">
        <div className="pro_description_txt_div">
          <h2>Product Description</h2>
          <ul>
            <li> Origin - Bangkok</li>
            <li> Carat Weight - 4.7775</li>
          </ul>
          <h3>What are its Benefits?</h3>
          <ul>
            <li>The Pukhraj Stone protects the wearer from evil and provides marital happiness and satisfaction for women especially.</li>
            <li> If the wearer is a woman, it provides her with a loving and prosperous groom, love, conjugal satisfaction, heirs, and happiness in her marital life.</li>
          </ul>
          <h3> What is the return policy and Estimated delivery duration?</h3>
          <ul>
            <li>We have a 7 days Return and Replacement Policy, ONLY if the product delivered is damaged or defective.</li>
            <li>This product requires energization of upto 2 or 3 days by the astrologer, following which it will be shipped to you.</li>
            <li>The estimated time of delivery is about 7 to 10 days.</li>
          </ul>

          <h3>Disclaimer </h3>
          <p> This ornament is for external use only, with risks to note: choking hazard for kids due to small parts, potential skin irritation for those with metal allergies. Handle with care to avoid breakage, not a toy, keep away from children. Keep dry, away from heat, and use gentle cleaners when cleaning. We're not liable for misuse. Contact us for assistance.</p>
        </div>
      </div>
      {/* col end */}

{/* col start rating  */}
<div className="col-lg-5 col-md-12 col-sm-12 col-12">
  <div className="rating_borer_min_di">
  <div className="rating_div_ti">
  <h4>Rating & Review</h4>
  </div>
  <div className="rating_flx_main_div">
    {/*  */}
  <div className="rating_div_txt_main">
   
    <h5>4.91</h5>
    <div className='rating_div'>
    {[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`fa fa-star ${index < writeRating ? 'checked' : ''}`}
                        onClick={() => handleClickwrite(index)}
                    ></span>
                );
            })}
     <h6><span className='fa fa-user'></span> 5,900</h6>
</div>
  </div>
  {/*  */}

{/*  */}
<div className="progress_bar_flex_main_div">
{/*  progress*/}
<div className="progress_div">
<b>5</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 5,000</div>
  </div>
</div>
</div>
{/* progress end */}

{/*  progress*/}
<div className="progress_div">
<b>4</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 500</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>3</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '60%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 250</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>2</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 100</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>1</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '30%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 50</div>
  </div>
</div>
</div>
{/* progress end */}


</div>
{/*  */}
</div>
  </div>

{/* write review start */}
<div className="write_review_div_main">
  <form action="">
<h3>Write Review</h3>
<div className="write_review">
{[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`fa fa-star ${index < ratingreview ? 'checked' : ''}`}
                        onClick={() => handleClickrating(index)}
                    ></span>
                );
            })}
</div>
<p>Describe Your Experience</p>
<textarea name="" id="" rows={4} className='form-control'></textarea>

<div class="d-grid gap-2">
  <button class="btn btn-primary" type="button">Submit</button>
</div>
</form>
</div>
{/* write review  end */}

</div>
{/* col end rating  */}

{/* col start rating  */}
<div className="col-lg-5 col-md-12 col-sm-12 col-12">
<div className="all_reviews_show_main_div">

  {/*  */}
<div className="all_review_media_div">
<div className="d-flex">
  <div className="flex-shrink-0">
    <img src="./images/revimg.png" alt="" className='img-fluid'/>
  </div>
  <div className="flex-grow-1 ms-2">
   <div className="all_revie_txt_div">
    <h4>8976XXXXXX</h4>
    <p>
      <span>2024-03-13</span> | <b>5.0</b>
     <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
     <span class="fa fa-star"></span></p>
     <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
   </div>
  </div>
</div>
</div>
  {/*  */}

{/*  */}
<div className="all_review_media_div">
<div className="d-flex">
  <div className="flex-shrink-0">
    <img src="./images/revimg.png" alt="" className='img-fluid'/>
  </div>
  <div className="flex-grow-1 ms-2">
   <div className="all_revie_txt_div">
    <h4>8976XXXXXX</h4>
    <p>
      <span>2024-03-13</span> | <b>5.0</b>
     <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
     <span class="fa fa-star"></span></p>
     <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
   </div>
  </div>
</div>
</div>
  {/*  */}


  {/*  */}
<div className="all_review_media_div">
<div className="d-flex">
  <div className="flex-shrink-0">
    <img src="./images/revimg.png" alt="" className='img-fluid'/>
  </div>
  <div className="flex-grow-1 ms-2">
   <div className="all_revie_txt_div">
    <h4>8976XXXXXX</h4>
    <p>
      <span>2024-03-13</span> | <b>5.0</b>
     <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
     <span class="fa fa-star"></span></p>
     <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
   </div>
  </div>
</div>
</div>
  {/*  */}

  {/*  */}
<div className="all_review_media_div">
<div className="d-flex">
  <div className="flex-shrink-0">
    <img src="./images/revimg.png" alt="" className='img-fluid'/>
  </div>
  <div className="flex-grow-1 ms-2">
   <div className="all_revie_txt_div">
    <h4>8976XXXXXX</h4>
    <p>
      <span>2024-03-13</span> | <b>5.0</b>
     <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
     <span class="fa fa-star"></span></p>
     <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
   </div>
  </div>
</div>
</div>
  {/*  */}

</div>
</div>
{/* col end rating  */}

    </div>
  </div>
</section>
{/*  */}

    </>
  );
};

export default Astrodetails;
