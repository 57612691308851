
export const ShopmallData = [
    {
        id:1,
        image:'../shopimg/1.webp',
        title:'Gemstone & Crystal',
        description:'A confused person always looks for a solution either by hook.',
        // 
        dt_title:'Yellow sapphire (pukhraj) 5.25',
        dt_imgage:'../shopimg/st01.jpg',
    },

    {
        id:2,
        image:'../shopimg/2.jpg',
        title:'Trending Wears',
        description:'A confused person always looks for a solution either by hook.'
    },



]

