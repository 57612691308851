import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import Sidenavbar from './Sidenavbar';
const Header = () => {
// top bar chat, call, video 
  const texts = [
    { text: "Call With Astrologer", link: "https://varta.healfate.com/call" },
    { text: "Chat With Astrologer", link: "https://varta.healfate.com/chat" },
    { text: "Videocall With Astrologer", link: "https://varta.healfate.com/video" }
  ];
  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // top bar chat, call, video end

  // scroll website then button show
  const [showButton, setshowButton] = useState(false);
  const webScroll = () =>{
    if(window.scrollY > 100){
      setshowButton(true);
    }

    else{
      setshowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', webScroll);
    return () => {
      window.removeEventListener('scroll', webScroll);
    };
  }, []);
  // scroll website then button show end
  

  return (
    <>
    
{/*  */}
<div className="alert_msg_main_div">
  <div className="alert alert-warning alert-dismissible fade show" role="alert">
    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
    <div className="msg_alert_div">
      <strong>TalkndHeal App</strong>
      <a href>Download Now</a>
    </div>   
  </div>
</div>
{/*  */}

  {/* top bar section start */}
  <section className="top_bar_section">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-lg-12 col-12">
          <div className="top_left_flexdiv">
            {/*  */}
            <div className="top_left_links">
              <a to="freeKundali" title="Free Kundali Software & Kundali Matching">Kundli</a>
              {/*<a to="yearlyHoroscope">Horoscope 2024</a>*/}
              <Link to="">Today's Horoscope</Link>
              <Link to="">Panchang</Link>
            </div>
            {/*  */}
            {/*  */}
            <div className="top_middle_chat_box">
             
              <Link to={texts[index].link}>
      <span className="fa fa-comments" />
      <span id="content-container">
        <b id="content"> {texts[index].text} </b>
      </span>
      <img src="../images/ic_online.gif" alt="Online Status" className="img-fluid" />
    </Link>

<span className="wallet_div">
  <a href="wallet">
    <span className="wallet_img_box">
      <img src="../images/wallet-2.png" alt />
      <span> ₹ </span>
    </span>
    <span className="amount_div">53,299</span>
  </a>
</span>



              <span className="share_btn"><Link className="fa fa-share" /></span>
            </div>
            {/*  */}
           
            <div className="top_signin_btn_div">
              <Link to="" data-bs-toggle="modal" data-bs-target="#login">SIGN IN/ SIGN UP</Link>
            </div>
            {/*  */}
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </section>
  {/* top bar end */}
  {/* logo navbar section start */}
  <div className="header_wrapper_div_main">
    <section className="logo_navbar">
      <div className="container">
        <div className="row">
          {/* col start */}
          <div className="col-lg-12 col-12">
            <div className="logo_navr_main_div">
              {/*  */}
              <div className="logo_div">
                <Link to="/"><img src="../logo/logo.png" alt className="img-fluid" /></Link>
              </div>
              {/*  */}

              {/*  */}
              <div className="logo_navlinks">
                <Link to="/astrodetails">Astrology</Link>
                <Link to="">Tarot</Link>
                <Link to="">Psychologist</Link>
                <Link to="">Bhrigu Samhita</Link>
                <Link to="">KP System</Link>
                <Link to="">Lal Kitab</Link>
                <Link to="">Palmistry</Link>
                <Link to="">Vastu</Link>
                <Link to="">Dietician</Link>
                <Link to="">Pranayama</Link>
                <Link to="">Yoga mudras</Link>
                <Link to="">Sun Rays Healing</Link>
                <Link to="">Sound and Words Healing</Link>
                <Link to="">Crystal Healing</Link>
                <Link to="">Acupressure Healing</Link>
                <Link to="">Magnet Healing</Link>
                <Link to="">Nature Healing</Link>
                <Link to="">Metal Healing</Link>
              </div>
              <div className="sign_in_btn" style={{ display: showButton ? 'block' : 'none' }}>
                <Link to="" data-bs-toggle="modal" data-bs-target="#login">SIGN IN/ SIGN UP</Link>
              </div>
              {/*  */}
              <div className="navbar_button_div">
             <span className="navbar_wallet_div">
  <a href="wallet">
    <span className="navbar_wallet_img_box">
      <img src="../images/wallet.svg" alt />
      <span> ₹ </span>
    </span>
    <span className="navbar_amount_div">5k</span>
  </a>
</span>

                 <i className="mobile_search_icon" aria-hidden="true"> <img src="../images/search.svg" alt="search" /></i>
                <button className="fa fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" />
                {/*  */}
                
              </div>
             
            </div>
          </div>
          {/* col end */}
        </div>
      </div>
      {/*  */}
  
    </section>
    {/* logo navbar section end */}
    {/* navbar section */}
    <section className="navbar_section_navbar">
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav navbar-childs">
              <li className="nav-item"><Link className="nav-link active" aria-current="page" to="">Home</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Kundli</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Kundli Matching</Link></li>
              {/* mega menu */}
              <li className="nav-item dropdown">
                <Link className="nav-link" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Horoscopes <span className="fa fa-angle-down" /></Link>
                <ul className="dropdown-menu">
                  <Link to="">Today's Horoscope</Link>
                  <Link to="">Tomorrow's Horoscope</Link>
                  <Link to="">Monthly Horoscope</Link>
                </ul>
              </li>
              {/* mega menu end*/}
              <li className="nav-item"><Link className="nav-link" to="">Prashna Kundli</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Panchang</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Chat with Astrologer</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Call with Astrologer</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Video call with Astrologer</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Join As An Astrologer</Link></li>
              <li className="nav-item"><Link className="nav-link" to="">Samuhik Puja</Link></li>
            </ul>
          </div>
        </div>
      </nav>
      
    </section>
  </div>
  {/* navbar section end */}


{/*  */}
<Sidenavbar/>
{/*  */}

    </>
 )
}

export default Header



