// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyAgdiV_5jFjFxWt42S7-o5IU2QwNEztMsg",
  authDomain: "react-52ce5.firebaseapp.com",
  projectId: "react-52ce5",
  storageBucket: "react-52ce5.appspot.com",
  messagingSenderId: "485136967969",
  appId: "1:485136967969:web:195a46d6f889397313b660",
  measurementId: "G-L0S2YP6KQ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);

    if (permission === "granted") {
          
  const token = await getToken(messaging,{
      vapidKey:
      "BITfcAGv6ujtM9t1DpVYFlsN7t8VM9TS6TkWWwi30v4wsdalHNIZUfjsu2t-MddJeqh9GrdQG2Me1OOf3yoSe84"
   });
   console.log("FCM Token:",token);
}
};
// export const generateToken = async () => {
//     try {
//         const permission = await Notification.requestPermission();
//         console.log(permission);

//         if (permission === "granted") {
//             const token = await getToken(messaging, {
//                 vapidKey: 
//                 "BITfcAGv6ujtM9t1DpVYFlsN7t8VM9TS6TkWWwi30v4wsdalHNIZUfjsu2t-MddJeqh9GrdQG2Me1OOf3yoSe84"
//             });
//             console.log(token);
//             return token;
//         } else {
//             console.error("Notification permission not granted");
//             return null;
//         }
//     } catch (error) {
//         console.error("Error getting token:", error);
//     }
// };