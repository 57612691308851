import React from 'react';

const Sidenavbar = () => {
  return (
    <>
    {/*============= mobile menu================= */}
<div className="main_div_sidebar">
  <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
    <div className="offcanvas-header"> 
      <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login"><img src="../images/a1.png" alt /> SIGN IN/SIGN UP <i className="fa fa-sign-in" /></a></h5>
      <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body offcanvas_scroll">
      {/* Example single danger button */}
      <div className="mobile_navbar">
        <ul className="main_ul">
          {/* Horoscopes li */}
          <li><a href="/"> <img src="../images/Home.svg" alt /> Home </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu2" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu2">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">    
                <li><a href="/astrodetails">Astrology</a></li>
                <li><a href="">Tarot</a></li>
                <li><a href="">Psychologist</a></li>
                <li><a href="">Bhrigu Samhita</a></li>
                <li><a href="">KP System</a></li>
                <li><a href="">Lal Kitab</a></li>
                <li><a href="">Palmistry</a></li>
                <li><a href="">Vastu</a></li>
                <li><a href="">Dietician</a></li>
                <li><a href="">Pranayama</a></li>
                <li><a href="">Yoga mudras</a></li>
                <li><a href="">Sun Rays Healing</a></li>
                <li><a href="">Sound and Words Healing</a></li>
                <li><a href="">Crystal Healing</a></li>
                <li><a href="">Acupressure Healing</a></li>
                <li><a href="">Magnet Healing</a></li>
                <li><a href="">Nature Healing</a></li>
                <li><a href="">Metal Healing</a></li>
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
          <li><a href="freeKundali"> <img src="../images/Kundli.svg" alt /> Kundli</a></li>
          <li><a href="kundliMatching"> <img src="../images/lovely.svg" alt /> Kundli Matching</a></li>
          {/* Horoscopes li */}
          <li><a href="javascript:void(0);"> <img src="../images/Home.svg" alt /> 
              Horoscopes </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
                <li><a href="todayHoroscope">Today's Horoscope</a></li>
                <li><a href="tomorrowHoroscope">Tomorrow's Horoscope</a></li>
                <li><a href="monthlyHoroscope">Monthly Horoscope</a></li>
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
          <li><a href=""> <img src="../images/gemini.svg" alt /> Prashna Kundli</a></li>
          <li><a href=""> <img src="../images/moon.svg" alt /> Panchang</a></li>
          {/*<li><a href="https://varta.healfate.com/chat"> <img src="../images/chat.svg" alt=""> Chat With Astrologer</a></li>*/}
          {/*<li><a href="https://varta.healfate.com/call"> <img src="../images/call.svg" alt=""> Talk to Astrologer</a></li>*/}
          {/*<li><a href="https://varta.healfate.com/video"> <img src="../images/video.svg" alt=""> Video to Astrologer</a></li>*/}
          <li><a href=""> <img src="../images/Pooja.svg" alt /> Samuhik Puja</a></li>
          {/*<li><a href="blogs"> <img src="../images/Newspaper.png" alt=""> Current Affairs</a></li>*/}
          <li><a href="joinAsAstrologer"> <img src="../images/Group76425.svg" alt /> Join As An Astrologer</a></li>
          <li><a href="javascript:void(0);"> <img src="../images/noti.png" alt /> Policies</a>
            <span data-bs-toggle="collapse" data-bs-target="#policies-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="policies-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
                <li><a href="">Disclaimer</a></li>
                <li><a href="">Terms &amp; Conditions</a></li>
                <li><a href="">Refund &amp; Cancellation</a></li>
                <li><a href="">Privacy Policy</a></li>
                <li><a href="">Pricing Policy</a></li>
              </ul>
            </div>
          </div>
         
        </ul>
        <ul className="left_icons_so_mobile">
          <li><a target="_blank" href=""><img src="../images/android.png" alt="Android App" /></a></li>
          <li><a target="_blank" href=""><img src="../images/apple.png" alt="iOS App" /></a></li>
          <li><a target="_blank" href=""><img src="../images/fb.png" alt="Facebook" /></a></li>
          <li><a target="_blank" href=""><img src="../images/insta.png" alt="Instagram" /></a></li>
          <li><a target="_blank" href=""><img src="../images/twitter.png" alt="Twitter" /></a></li>
          <li><a target="_blank" href=""><img src="../images/linkedin.png" alt="LinkedIn" /></a></li>
          <li><a target="_blank" href=""><img src="../images/yt.png" alt="Youtube" /></a></li>
        </ul>
      </div>
    </div>
  </div>
  {/*============= mobile menu end================= */}
  {/* mobile healing navbar section */}
  <div className="main_healing_nav_div">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          {/*  */}
          <div className="mobile_healing_nav menu-wrapper">
            <div className="menu-item">
              <a href="/astrodetails">Astrology</a>
              <a href="">Tarot</a>
              <a href="">Psychologist</a>
              <a href="">Bhrigu Samhita</a>
              <a href="">KP System</a>
              <a href="">Lal Kitab</a>
              <a href="">Palmistry</a>
              <a href="">Vastu</a>
              <a href="">Dietician</a>
              <a href="">Pranayama</a>
              <a href="">Yoga mudras</a>
              <a href="">Sun Rays Healing</a>
              <a href="">Sound and Words Healing</a>
              <a href="">Crystal Healing</a>
              <a href="">Acupressure Healing</a>
              <a href="">Magnet Healing</a>
              <a href="">Nature Healing</a>
              <a href="">Metal Healing</a>
            </div>
            {/* <span class="pointer left-pointer dis">
    <i class="fa fa-chevron-left"></i>
  </span>
  <span class="pointer right-pointer">
    <i class="fa fa-chevron-right"></i>
  </span> */}
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  </div>
  {/* end========= */}
</div>

    </>
  );
};

export default Sidenavbar;
