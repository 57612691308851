import React from 'react'
import OwlCarousel from 'react-owl-carousel';


const CarouselComponentDt = () => {

    
  const sliderimage = [
    "../shopimg/st01.jpg",
    "../shopimg/st02.jpg",
    "../shopimg/st03.jpg",
    "../shopimg/st04.jpg",
    "../shopimg/st05.jpg"
  ];

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 2000, // 2 seconds
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: false
      },
      1000: {
        items: 1,
        nav: true,
        loop: false
      }
    },
  };

  return (

    <>
    <div className="owlastrodt_div_mai">
      <OwlCarousel className="owl-theme" {...options}>
        
        {/* item div start */}
        {sliderimage.map((image, index) => (
        <div className="item" key={index}>
          <div className="astrodt_slider_img_box">
            <img src={image} alt={`Slide ${index + 1}`} className="img-fluid" />
          </div>
        </div>
      ))}
      {/* item div end */}
     
    </OwlCarousel>
    </div>
    </>

  )
}

export default CarouselComponentDt
