import React from 'react';
import { Link } from "react-router-dom";
import { ShopmallData } from '../data/ShopmallData';

const Home = () => {
  return (
    <>
     <section className="shopmall_section">
      <div className="container">
        {/* title div */}
        <div className="shop_title_div">
          <h1>TalkndHeal Mall Shop</h1>
          <p><b>Shop Best Online Astrology Products And Services</b></p>
        </div>
        {/* title div */}
{/* row search */}
<div className="row justify-content-end">
  
{/* search col div start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
  <div className="shopmall_saerch_box">
    
<form action="">
<div class="input-group">
  <input type="text" placeholder="Let's find what you're looking for..." className='form-control'/>
  <button className="btn" type="submit"><i class='bx bx-search-alt'></i></button>
</div>
</form>
  </div>
</div>
{/* search col div end */}

</div>
{/* end */}


        <div className="row">
          {/*  */}
         { ShopmallData.map((item,index)=>{
          return(
            <Shopmallitems shopdata={item} key={index}/>
          )
         }) }

        
          {/*  */}
        </div>
      </div>
     </section>
    </>
  )
}

export default Home;

const Shopmallitems =(props)=>{
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };


  return(
    <>
    {/* col start */}
    <div className="col-lg-3 col-md-6 col-sm-12 col-6">
      <Link to="">
      <div className="main_div_shopmall_items">
        <div className="shopmall_img_item_div">
         <img src={ props.shopdata.image } alt="" className='img-fluid'/>
        </div>
        <h3>{ truncateText(props.shopdata.title, 30) }</h3>
        <p>{ truncateText(props.shopdata.description, 40) }</p>
      </div>

      </Link>
    </div>
    {/* col end */}
    </>
  )
}
