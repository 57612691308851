import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>

    {/* map section  */}
  
  {/* ===================================footer section start =============== */}
  <section className="footer_section desktop_footer">
    <div className="container">
      {/* about footer */}
      <div className="footer_about_div" hidden>
        <h4>About Healfate</h4>
        <p>Healfate is the best astrology website for online Astrology predictions. Talk to Astrologer on call and get answers to all your worries by seeing the future life through Astrology Kundli Predictions from the best Astrologers from India. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.</p>
      </div>
      {/* end */}
      <div className="row">
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Astrology Services</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Tarot</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Psychologist</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Vastu</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Palmistry</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> KP System</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Lal Kitab</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Bhrigu Samhita</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Vedic</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Astrology</Link></li>
            </ul> 
           
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Healing Services</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Pranayama</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Yoga mudras</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Dietician</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Sun Rays Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Sound and Words Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Magnet Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Nature Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Acupressure Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Metal Healing</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Crystal Healing</Link></li>
            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Services</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Kundli</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Kundli Matching</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Lal Kitab</Link></li> 
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Panchang</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Vedic</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Chat With Astrologer</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Talk to Astrologer</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Video to Astrologer</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Join As An Astrologer</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Samuhik Puja</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Talk nd Heal  Sitemap</Link></li>
              {/*<li><a href="blogs"><i class='bx bxs-chevrons-right'></i> Current Affairs</a></li>*/}
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Feedback</Link></li>
            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start  */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_main_headti">
            <h5>Information</h5>
          </div>
          <div className="footer_links">
            <ul>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Refund &amp; Cancellation Policy</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Terms &amp; Conditions</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Privacy Policy</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Disclaimer</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> About Us</Link></li>
              <li><Link to=""><i className="bx bxs-chevrons-right" /> Pricing Policy</Link></li>
            </ul>
          </div>
          <div className="footer_conatct_div">
            <div className="footer_middle_headti">
              <h5>Contact us</h5>
            </div>
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bx-support" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p><Link to="">  We are available 24x7 on support, <span>click to raise ticket.</span></Link></p>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bx-envelope" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <b>Email ID:</b>
                  <a href="mailto:contact@healfate.com">  contact@healfate.com</a>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* socila icons div */}
            <div className="footer_social_ic">
              <a target="_blank" href="" className="fa fa-facebook" />
              <a target="_blank" href="" className="fa fa-instagram" />
              <a target="_blank" href="" className="fa fa-linkedin" />
              <a target="_blank" href="" className="fa fa-youtube" />
              <a target="_blank" href="" className="fa fa-twitter" />
              <a target="_blank" href="" className="fa fa-pinterest" />
            </div>
            {/* socila icons div end */}
            <div className="footer_middle_headti">
              <h5>Secure</h5>
            </div>
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bx-lock-alt" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p>Private &amp; Confidential</p>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bxs-user-check" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p>Verified Astrologers</p>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex align-items-middle">
              <div className="flex-shrink-0">
                <i className="bx bxs-wallet-alt" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p>Secure Payments</p>
                </div>
              </div>
            </div>
            {/* media div end */}
            <div className="footer_app_img">
              <Link to=""><img src="../images/android01.png" alt /></Link>
              <Link to=""><img src="../images/appstore.png" alt /></Link>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </section>
  {/* ===================================footer section end =============== */}
  {/* ===================================copyright section start================= */}
  <section className="copyright_section_sec">
    <div className="container">
      <div className="row">
        {/* col start  */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <p>© All copyrights reserved 2023 <span>Talk nd Heal  | Powered By Immortal Varta</span></p>
        </div>
        {/* col end */}
      </div>
    </div>
  </section>
  {/* ===================================copyright section end================= */}

{/* desktop footer icons div */}
<div className="desktop_fix_icon_div">
 
  <div className="desktop_btm_fx_flx">
    <Link to="" className="call_ft">
      <i className="fa fa-mobile-alt" />
      <span>Call</span>
    </Link>
    <Link to="" className="chat_ft">
      <i className="fas fa-comment-dots" />
      <span>Chat</span>
    </Link>
    <Link to="" className="live_ft">
      <i className="fa fa-video-camera" />
      <span>Video</span>
    </Link>
  </div>
</div>
{/* desktop footer icons div */}


{/*===========mobile footer fixed call chat icons ============== */}
<div className="main_div_footer_fxd_chat">
  <div className="main_div_flx_div_footer">
    {/* home icon */}
    <div className="home_icon_div">
      <a href="index">
        <span><img src="../images/homenormal.svg" alt /></span>
        <b>Home</b>
      </a>
    </div>
    {/* home icon end*/}
    {/* 4 div icon */}
    <div className="btn_type_icon_div">
      <a href="https://varta.healfate.com/chat">
        <span> <img src="../images/chatnormal.svg" alt /></span>
        <b>Chat</b>
      </a>
      <a href="https://varta.healfate.com/call">
        <span><img src="../images/callnormal.svg" alt /></span>
        <b>Call</b>
      </a>
      <a href="https://varta.healfate.com/video">
        <span><img src="../images/vcallnormal.svg" alt /></span>
        <b>Video</b>
      </a>
     
    </div>
    {/* 4 div icon end */}
   
    {/* profile icon */}
    <div className="home_icon_div">
      <a href="user-profile">
        <span><img src="../images/profilenormal.svg" alt /></span>
        <b>Profile</b>
      </a>
    </div>
   
    {/* profile icon end*/}
  </div>
</div>
{/*=========== mobile footer fixed call chat icons ============== */}



    </>
  )
}

export default Footer
